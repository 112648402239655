'use client'

import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useReducer,
} from 'react'
import {
  addProductToCart,
  applyDiscount,
  changeProductQuantity,
  completeCheckout,
  createCart,
  deleteCart,
  getCartByToken,
  mergeCartByToken,
  redirectToPayment,
  removeProductFromCart,
  setDeliveryAddress,
  setPaymentStepInvoice,
  setPaymentStepMethod,
  setShipmentMethod,
} from '@services/cart/api'
import { CartActions } from '@lib/constants/context'
import { DEFAULT_LANGUAGE_LOCALE_CODE } from '@lib/constants/language'
import {
  CHECKOUT_DELIVERY_CURRENT_TAB,
  CHECKOUT_PAYMENT_DATA,
  CHECKOUT_PICKUP_POINT,
  GUEST_CART_TOKEN,
} from '@lib/constants/storage'
import { getAuthState } from '@lib/handlers/auth'
import { TAddProductToCartBody, TAddressForm, TCartState } from '@lib/types'
import { PostitAddress } from '@lib/types/customer'
import handleSentry from '@lib/utils/handleSentry'
import withQueryClient from '@app/with-query-client'

// TODO: All of this file should be refactored. It would be nice to remove the context all together. Mutations should be moved into separate files

export interface DeliveryAddressFields {
  email?: string
  firstName: string
  lastName: string
  phoneNumber: string
  countryCode: string
  street: string
  apartment?: string
  city: string
  postcode: string
  cityOption?: { value?: PostitAddress; label: string }
  streetOption?: { value?: PostitAddress; label: string }
}

export interface DeliveryAddressFieldsWithMyAddress
  extends DeliveryAddressFields {
  myAddress: boolean
}

interface CartContextState {
  cart: TCartState
  isLoading: boolean
  isLoadingQuantity: boolean
  isCouponFalsy: boolean
  createCartAction: () => void
  getCartAction: (cartToken?: string) => void
  mergeCartAction: (cartToken?: string) => Promise<void>
  initCart: () => void
  addItemToCart: (
    body: TAddProductToCartBody,
    successCallback?: () => void
  ) => void
  resetCart: () => void
  removeItemFromCart: (itemId: number) => void
  changeItemQuantity: (
    itemId: number,
    quantity: number
  ) => Promise<number | undefined>
  setDeliveryMethod: (body: {
    shippingMethod: string
    shipmentId: string
    pickupPointId?: number
    pickupInventorySourceId?: number
  }) => Promise<void>
  setPaymentMethod: (paymentMethod: string, paymentId: string) => Promise<void>
  setShippingAddress: (
    address: DeliveryAddressFields,
    billingAddress?: DeliveryAddressFields,
    email?: string,
    pickupPointId?: number
  ) => Promise<boolean | undefined>
  setPaymentInvoice: (invoice: TAddressForm) => Promise<void>
  completeOrder: () => void
  redirectToPaymentAction: (
    tokenValue: string | null | undefined,
    paymentId: number | undefined
  ) => void
  setDiscountCode: (code: string) => void
  setCouponError: (value: boolean) => void
  deleteCurrentCart: () => void
  checkoutError?: AxiosError
  productAddToCartState: 'success' | 'error' | undefined
  resetAddToCartState: () => void
}

const initialState: CartContextState = {
  cart: {},
  isLoading: false,
  isLoadingQuantity: false,
  isCouponFalsy: false,
  createCartAction: () => {},
  getCartAction: () => {},
  initCart: () => {},
  addItemToCart: () => {},
  resetCart: () => {},
  removeItemFromCart: () => {},
  changeItemQuantity: async () => 0,
  setDeliveryMethod: async () => {},
  setPaymentMethod: async () => {},
  setShippingAddress: async (): Promise<boolean | undefined> => {
    return
  },
  setPaymentInvoice: async () => {},
  completeOrder: () => {},
  redirectToPaymentAction: () => {},
  setDiscountCode: () => {},
  setCouponError: () => {},
  deleteCurrentCart: () => {},
  mergeCartAction: async () => {},
  checkoutError: undefined,
  productAddToCartState: undefined,
  resetAddToCartState: () => {},
}

type Action =
  | { type: CartActions.setCartState; value: TCartState }
  | {
      type: CartActions.setLoadingState
      value: boolean
    }
  | {
      type: CartActions.setCouponError
      value: boolean
    }
  | { type: CartActions.setCheckoutError; value: AxiosError }
  | { type: CartActions.setLoadingQuantity; value: boolean }
  | {
      type: CartActions.setProductAddToCartState
      value: 'success' | 'error' | undefined
    }
  | { type: CartActions.resetAddToCartState }

const CartContext = createContext<CartContextState>(initialState)
CartContext.displayName = 'CartContext'

const cartReducer = (state: CartContextState, action: Action) => {
  switch (action.type) {
    case CartActions.setCartState: {
      return {
        ...state,
        cart: action.value,
      }
    }
    case CartActions.setLoadingState: {
      return {
        ...state,
        isLoading: action.value,
      }
    }
    case CartActions.setLoadingQuantity: {
      return {
        ...state,
        isLoadingQuantity: action.value,
      }
    }
    case CartActions.setCouponError: {
      return {
        ...state,
        isCouponFalsy: action.value,
      }
    }
    case CartActions.setCheckoutError: {
      return {
        ...state,
        checkoutError: action.value,
      }
    }
    case CartActions.setProductAddToCartState: {
      return {
        ...state,
        productAddToCartState: action.value,
      }
    }
    case CartActions.resetAddToCartState: {
      return {
        ...state,
        productAddToCartState: undefined,
      }
    }
  }
}

const CartProvider: FC<{ children?: ReactNode }> = withQueryClient((props) => {
  const [state, dispatch] = useReducer(cartReducer, initialState)
  const setLoadingState = useCallback(
    (state: boolean) =>
      dispatch({
        type: CartActions.setLoadingState,
        value: state,
      }),
    [dispatch]
  )

  const setLoadingQuantity = useCallback(
    (state: boolean) =>
      dispatch({
        type: CartActions.setLoadingQuantity,
        value: state,
      }),
    [dispatch]
  )

  const setProductAddToCartState = useCallback(
    (state: 'success' | 'error' | undefined) =>
      dispatch({
        type: CartActions.setProductAddToCartState,
        value: state,
      }),
    [dispatch]
  )

  const resetProductAddToCartState = useCallback(
    () =>
      dispatch({
        type: CartActions.resetAddToCartState,
      }),
    [dispatch]
  )

  // Mutations
  const { mutateAsync: createCartMt } = useMutation(createCart)
  const { mutateAsync: getCartMt } = useMutation(getCartByToken)

  const { mutateAsync: mergeCartMt } = useMutation(async (token?: string) =>
    mergeCartByToken(token ?? '')
  )

  const { mutateAsync: addItemToCartMt } = useMutation(
    async ({
      cartToken,
      ...body
    }: TAddProductToCartBody & { cartToken?: string }) =>
      addProductToCart(cartToken ?? state.cart.tokenValue, body)
  )
  const { mutateAsync: removeItemFromCartMt } = useMutation(
    async (itemId: number) =>
      removeProductFromCart(state.cart.tokenValue, itemId)
  )
  const { mutateAsync: changeProductQuantityMt } = useMutation(
    async (body: { itemId: number; quantity: number }) =>
      changeProductQuantity(state.cart.tokenValue, body.itemId, body.quantity)
  )
  const { mutateAsync: applyDiscountMt } = useMutation(
    async (discountCode: string) =>
      applyDiscount(state.cart.tokenValue, discountCode)
  )

  const { mutateAsync: setShipment } = useMutation(
    async ({
      shipmentId,
      shippingMethod,
      pickupPointId,
      pickupInventorySourceId,
    }: {
      shippingMethod: string
      shipmentId: string
      pickupPointId?: number
      pickupInventorySourceId?: number
    }) =>
      setShipmentMethod({
        token: state.cart.tokenValue,
        shippingMethod,
        shipmentId,
        pickupPointId,
        pickupInventorySourceId,
      })
  )
  const { mutateAsync: setPayment } = useMutation(
    async (body: { paymentMethod: string; paymentId: string }) =>
      setPaymentStepMethod(
        state.cart.tokenValue,
        body.paymentMethod,
        body.paymentId
      )
  )

  const { mutateAsync: setAddress } = useMutation(
    async ({
      address,
      billingAddress,
      email,
      pickupPointId,
    }: {
      email?: string
      billingAddress?: TAddressForm
      address: TAddressForm
      pickupPointId?: number
    }) =>
      setDeliveryAddress(
        state.cart.tokenValue,
        address,
        billingAddress,
        email,
        pickupPointId
      )
  )

  const { mutateAsync: setInvoice } = useMutation(
    async (invoice: TAddressForm) =>
      setPaymentStepInvoice(state.cart.tokenValue, invoice)
  )

  const { mutateAsync: completeCheckoutMt } = useMutation(async () =>
    completeCheckout(state.cart.tokenValue)
  )

  const { mutateAsync: redirectToPaymentMt } = useMutation(
    async (body: { tokenValue: string; paymentId: number | undefined }) =>
      redirectToPayment(body.tokenValue, body.paymentId)
  )

  const { mutateAsync: deleteCartMt } = useMutation(async () =>
    deleteCart(state?.cart?.tokenValue)
  )

  // Actions
  const setCartState = useCallback(
    (token: TCartState) =>
      dispatch({
        type: CartActions.setCartState,
        value: token,
      }),
    [dispatch]
  )

  const setCouponError = useCallback(
    (state: boolean) =>
      dispatch({
        type: CartActions.setCouponError,
        value: state,
      }),
    [dispatch]
  )

  const setCheckoutError = useCallback(
    (error: AxiosError) =>
      dispatch({
        type: CartActions.setCheckoutError,
        value: error,
      }),
    [dispatch]
  )

  const createCartAction = async () => {
    try {
      setLoadingState(true)

      // TODO: Temporary localeCode
      const response = await createCartMt({
        localeCode: DEFAULT_LANGUAGE_LOCALE_CODE,
      })

      if (response?.id) {
        setCartState(response)

        localStorage.setItem(GUEST_CART_TOKEN, response.tokenValue ?? '')
      }

      return response.tokenValue
    } catch (error: unknown) {
      handleSentry(error)
    } finally {
      setLoadingState(false)
    }
  }

  const getCartAction = async (
    cartToken?: string,
    cb?: (cartToken: string) => void
  ) => {
    try {
      if (cartToken) {
        setLoadingState(true)

        const response = await getCartMt({ token: cartToken })

        if (response?.id) {
          setCartState(response)
        }
      } else {
        const token = await createCartAction()

        if (token && cb) {
          cb(token)
        }
      }
    } catch {
      const { token } = getAuthState()

      if (!token) {
        localStorage.removeItem(GUEST_CART_TOKEN)
        await createCartAction()
      }
    } finally {
      setLoadingState(false)
    }
  }

  const mergeCartAction = async (cartToken?: string) => {
    try {
      setLoadingState(true)

      if (!cartToken) {
        return await getCartAction()
      }

      const response = await mergeCartMt(cartToken)

      if (response?.id) {
        setCartState(response)
      }
    } catch (error: unknown) {
      handleSentry(error)
    } finally {
      setLoadingState(false)
    }
  }

  const addItemToCart = async (
    body: TAddProductToCartBody,
    successCallback?: () => void
  ) => {
    try {
      setLoadingState(true)

      if (!state.cart.tokenValue) {
        await getCartAction(undefined, async (token) => {
          try {
            const response = await addItemToCartMt({
              ...body,
              cartToken: token,
            })

            if (response?.id) {
              setCartState(response)
            }

            setProductAddToCartState('success')
          } catch {
            setProductAddToCartState('error')
          }
        })

        return
      }

      const response = await addItemToCartMt(body)

      if (response?.id) {
        setCartState(response)
      }

      setProductAddToCartState('success')

      successCallback?.()
    } catch (error: unknown) {
      handleSentry(error)

      setProductAddToCartState('error')
    } finally {
      setLoadingState(false)

      setTimeout(() => {
        resetProductAddToCartState()
      }, 2500)
    }
  }

  const removeItemFromCart = async (itemId: number) => {
    try {
      setLoadingState(true)

      const response = await removeItemFromCartMt(itemId)

      if (response?.id) {
        setCartState(response)
      }
    } catch (error: unknown) {
      handleSentry(error)
    } finally {
      setLoadingState(false)
    }
  }

  const changeItemQuantity = async (itemId: number, quantity: number) => {
    try {
      setLoadingQuantity(true)

      const response = await changeProductQuantityMt({ itemId, quantity })

      if (response?.id) {
        setCartState(response)
      }

      return (
        response?.items?.find((item) => item.id === itemId)?.quantity ??
        quantity
      )
    } catch (error: unknown) {
      setProductAddToCartState('error')

      setTimeout(() => {
        resetProductAddToCartState()
      }, 5000)

      handleSentry(error)
    } finally {
      setLoadingQuantity(false)
    }
  }

  const resetCart = () => {
    setCartState({})
  }

  const setDeliveryMethod = async ({
    shipmentId,
    shippingMethod,
    pickupPointId,
    pickupInventorySourceId,
  }: {
    shippingMethod: string
    shipmentId: string
    pickupPointId?: number
    pickupInventorySourceId?: number
  }) => {
    try {
      setLoadingState(true)

      const response = await setShipment({
        shippingMethod,
        shipmentId,
        pickupPointId,
        pickupInventorySourceId,
      })

      if (response?.id) {
        setCartState(response)
      }
    } catch (error: unknown) {
      handleSentry(error)
    } finally {
      setLoadingState(false)
    }
  }

  const setPaymentMethod = async (paymentMethod: string, paymentId: string) => {
    try {
      setLoadingState(true)

      const response = await setPayment({
        paymentMethod,
        paymentId,
      })

      if (response?.id) {
        setCartState(response)
      }
    } catch (error: unknown) {
      handleSentry(error)
    } finally {
      setLoadingState(false)
    }
  }

  const setShippingAddress = async (
    address: DeliveryAddressFields,
    billingAddress?: DeliveryAddressFields,
    email?: string,
    pickupPointId?: number
  ) => {
    try {
      setLoadingState(true)

      const updatedAddress = {
        ...address,
        street: address.apartment
          ? `${address.street} - ${address.apartment}`
          : address.street,
      }

      delete updatedAddress.apartment

      const response = await setAddress({
        address: updatedAddress,
        billingAddress,
        email,
        pickupPointId,
      })

      if (response?.id) {
        setCartState(response)
      }
    } catch (error: unknown) {
      if (
        error instanceof AxiosError &&
        error?.response?.status === 422 &&
        error?.response?.data?.['detail'] ===
          'Please enter a valid email address with a resolvable domain'
      ) {
        return true
      }

      handleSentry(error)
    } finally {
      setLoadingState(false)
    }
  }

  const setPaymentInvoice = async (invoice: TAddressForm) => {
    try {
      setLoadingState(true)

      const response = await setInvoice(invoice)

      if (response?.id) {
        setCartState(response)
      }
    } catch (error: unknown) {
      handleSentry(error)
    } finally {
      setLoadingState(false)
    }
  }

  const completeOrder = async () => {
    try {
      setLoadingState(true)

      const response = await completeCheckoutMt()

      if (response?.tokenValue && response?.payments?.[0].id) {
        setCartState(response)

        localStorage.removeItem(GUEST_CART_TOKEN)
        localStorage.removeItem(CHECKOUT_DELIVERY_CURRENT_TAB)
        localStorage.removeItem(CHECKOUT_PICKUP_POINT)
        localStorage.removeItem(CHECKOUT_PAYMENT_DATA)

        await redirectToPaymentMt({
          tokenValue: response.tokenValue,
          paymentId: response?.payments?.[0].id,
        })
      }
    } catch (error: unknown) {
      handleSentry(error)

      if (error instanceof AxiosError) {
        setCheckoutError(error)
      }
    } finally {
      setLoadingState(false)
    }
  }

  const setDiscountCode = async (discountCode: string) => {
    try {
      setLoadingState(true)

      const response = await applyDiscountMt(discountCode)

      if (response?.id) {
        setCartState(response)
      }

      setCouponError(false)
    } catch (error: unknown) {
      setCouponError(true)

      handleSentry(error)
    } finally {
      setLoadingState(false)
    }
  }

  const deleteCurrentCart = async () => {
    try {
      setLoadingState(true)

      const response = await deleteCartMt()

      if (!response) {
        return
      }

      localStorage.removeItem(GUEST_CART_TOKEN)

      getCartAction()
    } catch (error: unknown) {
      handleSentry(error)
    } finally {
      setLoadingState(false)
    }
  }

  const redirectToPaymentAction = async (
    tokenValue: string | null | undefined,
    paymentId: number | undefined
  ) => {
    try {
      setLoadingState(true)

      if (tokenValue && paymentId) {
        await redirectToPaymentMt({
          paymentId,
          tokenValue,
        })
      }
    } catch (error: unknown) {
      handleSentry(error)
    } finally {
      setLoadingState(false)
    }
  }

  const initCart = async () => {
    const cartToken = localStorage.getItem(GUEST_CART_TOKEN)

    if (cartToken) {
      await getCartAction(cartToken)
    } else {
      const { token } = getAuthState()
      if (token) {
        await createCartAction()
      }
    }
  }

  const value = {
    ...state,
    createCartAction,
    getCartAction,
    mergeCartAction,
    initCart,
    addItemToCart,
    removeItemFromCart,
    changeItemQuantity,
    resetCart,
    setDeliveryMethod,
    setShippingAddress,
    setPaymentMethod,
    setPaymentInvoice,
    completeOrder,
    redirectToPaymentAction,
    setDiscountCode,
    setCouponError,
    deleteCurrentCart,
  }

  return <CartContext.Provider value={value} {...props} />
})

export const useCart = () => {
  const context = useContext(CartContext)
  if (context === undefined) {
    throw new Error(`useCart must be used within a CartProvider`)
  }
  return context
}

export default CartProvider
